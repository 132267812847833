'use client';

import Cookies from 'js-cookie';
import { usePathname, useRouter } from 'next/navigation';
import React, { createContext, ReactNode, useEffect, useState } from 'react';

import { analytics } from 'lib/analytics';
import { trpc } from 'lib/trpc';
import { ServerTypes, User } from 'lib/types';

import Spinner from 'components/Spinner';
import { DateTime } from 'luxon';

let processedCode = '';
let already_running = false;

type AuthContext = [
  Partial<ServerTypes.UserWithSubscriptionDetails> | null,
  React.Dispatch<
    | React.SetStateAction<Partial<ServerTypes.UserWithSubscriptionDetails> | null>
    | (() => undefined)
  >,
  React.Dispatch<React.SetStateAction<boolean> | (() => undefined)>,
];

const AuthContext = createContext<AuthContext>([
  null,
  () => undefined,
  () => undefined,
]);

const pathRE = {
  loggedInOnly: /\/(post-registration)/,
  loggedOutOnly:
    /\/(create-account|forgotten-password|login|password-reset|verify)/,
};

export default function AuthProvider({ children }: { children: ReactNode }) {
  const router = useRouter();
  const pathname = usePathname();

  const [user, setUser] =
    useState<Partial<ServerTypes.UserWithSubscriptionDetails> | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    async function getUser() {
      if (already_running) return; // don't re-run too quickly (e.g. double run on start up)
      already_running = true;
      setTimeout(() => {
        already_running = false;
      }, 100);
      const params = new URLSearchParams(location.search);
      const code = params.get('code');
      let signedInGoogle = false;
      try {
        if (code && code !== processedCode) {
          // sso
          await trpc.auth.ssoLogin.mutate({ code });
          processedCode = code;
          signedInGoogle = true;
        }
        const accessToken = Cookies.get('accessToken');
        const sub = Cookies.get('sub');
        const loggedIn = accessToken && sub;

        if (!loggedIn) {
          throw new Error('user not logged in');
        }

        let dbUser: ServerTypes.UserWithSubscriptionDetails =
          (await trpc.user.getByAuthId.query(
            sub,
          )) as ServerTypes.UserWithSubscriptionDetails;

        if (!dbUser) {
          // if there is no user attempt to create one
          const currentDate = DateTime.local().toISODate();
          await trpc.user.create.mutate({ authUserId: sub, currentDate });
          dbUser = (await trpc.user.getByAuthId.query(
            sub,
          )) as ServerTypes.UserWithSubscriptionDetails;
          if (signedInGoogle) {
            analytics.signedupGoogle(dbUser);
          } else {
            analytics.signedupEmail(dbUser);
          }
        } else {
          if (signedInGoogle) {
            analytics.signedInGoogle(dbUser);
          }
        }

        if (!Boolean(dbUser.stripeCustomerId)) {
          await trpc.user.createStripeCustomerId.mutate({
            authUserId: dbUser.authUserId,
            id: dbUser.id,
          });
          dbUser = (await trpc.user.getByAuthId.query(
            sub,
          )) as ServerTypes.UserWithSubscriptionDetails;
        }

        const awsUser = await trpc.auth.getUser.query({ accessToken });

        const authUser: Partial<User> = {
          authUserId: sub,
          userName: awsUser.Username,
        };

        const newUser = { ...authUser, ...dbUser };
        // TODO: Not removed yet, because this should be switched over to check for non-oauth login.
        // const hasCompletedRegistration = newUser.firstName && newUser.lastName;
        // if (pathname !== '/post-registration' && !hasCompletedRegistration) {
        //   return router.push('/post-registration');
        // }
        // if (pathname === '/post-registration' && hasCompletedRegistration) {
        if (pathname === '/post-registration') {
          return router.push('/');
        }
        if (pathname.match(pathRE.loggedOutOnly)) {
          return router.push('/');
        }
        setUser(newUser);
      } catch (err) {
        if (pathname.match(pathRE.loggedInOnly)) {
          return router.push('/login');
        }
        if (pathname === '/verify' && !params.has('email')) {
          return router.push('/login');
        }
        if (!code) {
          // user just came from SSO so don't sign them out
          setUser(null);
        }
      }
      setIsLoading(false);
    }

    getUser();
  }, [pathname, isLoading, router]);

  if (isLoading) {
    return <Spinner fullScreen={true} />;
  }
  return (
    <AuthContext.Provider value={[user, setUser, setIsLoading]}>
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext };
