import { isStatisticsResponse } from '@server/validators';
import useAuth from 'hooks/useAuth';
import { analytics } from 'lib/analytics';
import { trpc } from 'lib/trpc';
import { QuestionAndAnswer, ServerTypes } from 'lib/types';
import { DateTime } from 'luxon';
import { AppRouterInstance } from 'next/dist/shared/lib/app-router-context.shared-runtime';
import { useParams, useRouter } from 'next/navigation';
import { isDataIrrelevant } from 'utils/dataRelevancy';
import { makeCompare } from './makeCompare';
import {
  FollowUpQA,
  RequestData,
  Source,
  qnaAPI,
  type StatisticsResponse,
} from './qnaAPI';
import {
  AdditionalQAProps,
  QuestionAnswer,
  QuestionAnswerState,
} from './questionAnswer';
import { SavedSearches } from './savedSearches';
import { StateItem, useStateItem } from './stateItem';
import { StateItems, useStateItems } from './stateItems';
import { Summary, useSummary } from './summary';

export interface SourceWithState extends Source {
  isDeleted: boolean;
}

export interface SourceTitleWithState {
  title: string;
  isDeleted: boolean;
  url: string;
}

export type StreamType = 'open_request' | 'open_request_followup';

const SUGGESTED_FOLLOW_UPS_NUMBER = 10;
export class Search {
  constructor(
    private queryId: StateItem<string>,
    private user: Partial<ServerTypes.UserWithProfessions> | null,
    private router: AppRouterInstance,
    private text: StateItem<string>,
    private title: StateItem<string>,
    private sources: StateItem<Source[]>,
    private statistics: StateItem<StatisticsResponse[]>,
    private statisticsIsLoaded: StateItem<boolean>,
    private deletedSources: StateItem<Set<Source>>,
    private tags: StateItem<string[]>,
    private savedSearches: SavedSearches,
    private questionAnswers: StateItems<QuestionAndAnswer, QuestionAnswer>,
    private allFollowUpQAs: StateItem<FollowUpQA[]>,
    private suggestedFollowUps: StateItem<string[]>,
    private isFetchingSuggesteFollowUps: StateItem<boolean>,
    private summary: Summary,
    private chatGPTAnswer: StateItem<string>,
    private mistralAnswer: StateItem<string>,
    private geminiAnswer: StateItem<string>,
    private claudeAnswer: StateItem<string>,
    private isPaywallModalOpen: StateItem<boolean>,
  ) {}

  /**
   * Retrieve the query id for the question
   *
   * @param latest whether to use the latest or state value (false should be used when rendering)
   * @returns
   */
  public getQueryId(latest = false): string {
    const queryId = this.queryId.get(latest);
    if (!queryId) throw new Error('Query id not set');
    return queryId;
  }

  private setQueryId(queryId: string): void {
    this.queryId.set(queryId);
  }

  /**
   * Get the text of the question from the form
   *
   * @param latest whether to use the latest or state value (false should be used when rendering)
   * @returns
   */
  public getText(latest = false): string {
    const text = this.text.get(latest);
    return text;
  }

  /**
   * Get the title of the search
   *
   * @param latest whether to use the latest or state value (false should be used when rendering)
   * @returns
   */
  public getTitle(latest = false): string {
    return this.title.get(latest);
  }

  /**
   * Get all the questions and answers
   *
   * @param latest whether to use the latest or state value (false should be used when rendering)
   * @returns
   */
  public getQuestionsAndAnswers(latest = false): QuestionAnswer[] {
    return this.questionAnswers.getAll(latest);
  }

  /**
   * Get the summary
   *
   * @returns
   */
  public getSummary(): Summary {
    return this.summary;
  }

  /**
   * Get IsFetchingSuggestedFollowUps
   * @param latest whether to use the latest or state value (false should be used when rendering)
   * @returns
   */
  public getIsFetchingSuggestedFollowUps(latest: boolean): boolean {
    return this.isFetchingSuggesteFollowUps.get(latest);
  }

  /**
   * Get the last added question and answer
   *
   * @param latest whether to use the latest or state value (false should be used when rendering)
   * @returns
   */

  public getLastAddedQuestion(latest: boolean): QuestionAnswer {
    const lastQuestionIndex = this.questionAnswers.size(latest) - 1;
    return this.questionAnswers.getAt(lastQuestionIndex);
  }

  /**
   * Whether there have been any follow up questions
   *
   * @param latest whether to use the latest or state value (false should be used when rendering)
   * @returns
   */
  public hasFollowUps(latest = false): boolean {
    return this.questionAnswers.size(latest) >= 1;
  }

  /**
   * Returns the logged in user.
   *
   * @returns user
   */
  public getUser(): Partial<ServerTypes.UserWithProfessions> | null {
    return this.user;
  }

  /**
   * Get the chatGPT answer for the first question in the search thread
   *
   * @param latest whether to use the latest or state value (false should be used when rendering)
   * @returns
   */
  public getChatGPTAnswer(latest = false): string {
    return this.chatGPTAnswer.get(latest);
  }

  /**
   * Set the chatGPT answer for the first question in the search thread
   *
   * @param answer
   * @returns
   */
  public setChatGPTAnswer(answer: string): void {
    this.chatGPTAnswer.set(answer);
  }

  /**
   * Get the Mistral answer for the first question in the search thread
   *
   * @param latest whether to use the latest or state value (false should be used when rendering)
   * @returns
   */
  public getMistralAnswer(latest = false): string {
    return this.mistralAnswer.get(latest);
  }

  /**
   * Set the Mistral answer for the first question in the search thread
   *
   * @param answer
   * @returns
   */
  public setMistralAnswer(answer: string): void {
    this.mistralAnswer.set(answer);
  }

  /**
   * Get the Gemini answer for the first question in the search thread
   *
   * @param latest whether to use the latest or state value (false should be used when rendering)
   * @returns
   */
  public getGeminiAnswer(latest = false): string {
    return this.geminiAnswer.get(latest);
  }

  /**
   * Set the Gemini answer for the first question in the search thread
   *
   * @param answer
   * @returns
   */
  public setGeminiAnswer(answer: string): void {
    this.geminiAnswer.set(answer);
  }

  /**
   * Get the Claude answer for the first question in the search thread
   *
   * @param latest whether to use the latest or state value (false should be used when rendering)
   * @returns
   */
  public getClaudeAnswer(latest = false): string {
    return this.claudeAnswer.get(latest);
  }

  /**
   * Set the Claude answer for the first question in the search thread
   *
   * @param answer
   * @returns
   */
  public setClaudeAnswer(answer: string): void {
    this.claudeAnswer.set(answer);
  }

  /**
   * Get the sources that have been deleted by the user
   *
   * @param latest whether to use the latest or state value (false should be used when rendering)
   * @returns
   */
  private getDeletedSources(latest = false): string[] {
    const deletedSourcesSet = this.deletedSources.get(latest);
    return [...deletedSourcesSet].map((source) => source.url);
  }

  /**
   * Set the follow-up questions
   *
   * @param search search of type ServerTypes.SearchWithQAs not instance of Search class
   * @returns
   */
  private setFollowUps(search: ServerTypes.SearchWithQAs) {
    const allFollowUps: FollowUpQA[] = search.QuestionAnswer.map((qa) => ({
      question: qa.question,
      answer: qa.answer,
    }));
    this.allFollowUpQAs.set(allFollowUps);
  }

  /**
   * Get the follow-up questions
   *
   * @param latest whether to use the latest or state value (false should be used when rendering)
   * @returns
   */
  private getAllFollowUps(latest: boolean): FollowUpQA[] {
    return this.allFollowUpQAs.get(latest);
  }

  private filterFollowUps(question: string): FollowUpQA[] {
    const allFollowUps = this.getAllFollowUps(true);
    return allFollowUps.filter((followUp) => followUp.question !== question);
  }

  /**
   * Set the suggested follow-up questions
   *
   * @param suggestedQuestions string array of suggested follow-up questions
   */
  private setSuggestedFollowUps(suggestedQuestions: string[]): void {
    this.suggestedFollowUps.set(suggestedQuestions);
  }

  /**
   * Get the suggested follow-up questions
   *
   * @param latest whether to use the latest or state value (false should be used when rendering)
   * @returns
   */
  public getSuggestedFollowUps(latest: boolean): string[] {
    return this.suggestedFollowUps.get(latest);
  }

  /**
   * Get the title from the QnA service and save to the DB. Then update the saved search
   * list form the DB.
   *
   * Cannot be used in rendering (changes state and relies on refs).
   *
   * @returns
   */
  private async fetchTitle(question: string): Promise<void> {
    const queryId = this.getQueryId(true);
    const title = await qnaAPI.fetchTitle(question);
    this.title.set(title);
    if (!this.user) return;
    await trpc.search.setTitle.mutate({ queryId, title });
    this.savedSearches.fetch();
  }

  /**
   * Whether any of the questions' answers are streaming.
   *
   * @param latest whether to use the latest or state value (false should be used when rendering)
   * @returns
   */
  public getIsStreaming(latest = false): boolean {
    return this.questionAnswers.some((qa) => qa.getIsStreaming(latest), latest);
  }

  private getDeletedSourcesUrls(latest = false): Set<string> {
    const deletedSourcesSet = this.deletedSources.get(latest);
    return new Set([...deletedSourcesSet].map((source) => source.url));
  }

  /**
   * Get the list of all sources in alphabetical order by title along with their
   * state (deleted or not)
   *
   * @param latest whether to use the latest or state value (false should be used when rendering)
   * @returns
   */
  public getSources(latest = false): SourceWithState[] {
    const result: SourceWithState[] = [];
    const deletedSourcesUrls = this.getDeletedSourcesUrls(latest);
    for (const source of this.sources.get(latest)) {
      const sourceWithState: SourceWithState = {
        isDeleted: deletedSourcesUrls.has(source.url),
        title: source.title,
        url: source.url,
      };
      result.push(sourceWithState);
    }
    result.sort(makeCompare('title'));
    return result;
  }

  /**
   * Get the list of all statistics
   *
   * @param latest whether to use the latest or state value (false should be used when rendering)
   * @returns
   */
  public getStatistics(latest = false): StatisticsResponse[] {
    return this.statistics.get(latest);
  }

  /**
   * Get the loading status of the statistics
   *
   * @param latest whether to use the latest or state value (false should be used when rendering)
   * @returns
   */
  public getStatisticsIsLoaded(latest = false): boolean {
    return this.statisticsIsLoaded.get(latest);
  }

  /**
   * Get if th paywall modal is opened or not
   *
   * @param latest whether to use the latest or state value (false should be used when rendering)
   * @returns
   */
  public getIsPaywallModalOpened(latest = false): boolean {
    return this.isPaywallModalOpen.get(latest);
  }

  /**
   * set value for paywall modal being opened to either true or false
   */
  public setIsPaywallModalOpened(value: boolean) {
    this.isPaywallModalOpen.set(value);
  }

  /**
   * Check if user is allowed to ask question
   *
   * @param userId user db id
   * @param authUserId auth user id
   * @returns
   */
  private async isAllowedToAskQuestion(userId: number, authUserId: string) {
    const currentDate = DateTime.local().toISODate();
    return await trpc.user.getIsAllowedToAskQuestion.query({
      id: userId as number,
      authUserId,
      userDate: currentDate,
    });
  }

  /**
   * Get all sources grouped by title in alphabetical order by title
   *
   * @param latest whether to use the latest or state value (false should be used when rendering)
   * @returns
   */
  public getSourceTitles(latest = false): SourceTitleWithState[] {
    const processedTitles = new Set<string>();
    const deletedSourcesUrls = this.getDeletedSourcesUrls(latest);
    const result: SourceTitleWithState[] = [];
    for (const source of this.sources.get(latest)) {
      result.push({
        isDeleted: deletedSourcesUrls.has(source.url),
        title: source.title,
        url: source.url,
      });
      processedTitles.add(source.title);
    }
    result.sort(makeCompare('title'));
    return result;
  }

  /**
   * Toggles the deleted state of a set of sources (grouped by title)
   *
   * Cannot be used in rendering (changes state and relies on refs).
   *
   * @param title
   * @returns
   */
  public toggleSourceTitle(title: string): void {
    const deletedSources = this.deletedSources.get(true);
    const deletedSourcesArr = [...this.deletedSources.get(true)];
    const queryId = this.getQueryId(true);

    for (const source of this.sources.get(true)) {
      if (source.title !== title) continue;
      const deletedSource = deletedSourcesArr.find((x) => x.url === source.url);
      if (deletedSource) {
        deletedSources.delete(deletedSource);
        analytics.reenabledSource(
          this.user,
          queryId,
          this.getText(),
          source.title,
          source.url,
        );
      } else {
        deletedSources.add(source);
        analytics.deletedSource(
          this.user,
          queryId,
          this.getText(),
          source.title,
          source.url,
        );
      }
    }
    this.deletedSources.set(deletedSources);
    trpc.search.setDeletedSources.mutate({
      queryId,
      deletedSources: [...deletedSources],
    });
    //update the deleted sources for all QAs
    const allQnAs = this.getQuestionsAndAnswers(true);
    allQnAs.forEach((qa) => qa.setDeletedSources(this.getDeletedSources(true)));
  }

  /**
   * Whether any sources have been deleted
   *
   * @param latest whether to use the latest or state value (false should be used when rendering)
   * @returns
   */
  public hasDeletedSources(latest = false): boolean {
    const deletedSources = this.deletedSources.get(latest);
    return deletedSources.size > 0;
  }

  /**
   * Fetch sources from the QnA service, update the DB and then update the saved searches.
   *
   * Cannot be used in rendering (changes state and relies on refs).
   *
   * @param question
   * @returns
   */
  private async fetchSources(
    question: string,
    questionId: number,
  ): Promise<void> {
    const queryId = this.getQueryId(true);
    const sources = await qnaAPI.fetchSources(question, questionId, queryId);
    this.sources.set(sources);
    if (!this.user) return;
    trpc.search.setSources.mutate({ queryId, sources });
  }

  /**
   * Fetch statistics from the QnA service.
   *
   * Cannot be used in rendering (changes state and relies on refs).
   *
   * @param question
   * @returns
   */
  private async fetchStatistics(
    question: string,
    questionId: number,
    sourceList: string[],
    followupQas: FollowUpQA[],
  ): Promise<void> {
    const queryId = this.getQueryId(true);
    const existingStatistics = this.statistics.get(true);
    this.statisticsIsLoaded.set(false);
    this.statistics.set([]);

    const statistics = await qnaAPI.fetchStatistics({
      question,
      question_id: questionId,
      query_id: queryId,
      source_list: sourceList,
      followup_qas: followupQas,
      with_upload: false,
    });

    // Validating response with zod
    isStatisticsResponse
      .array()
      .safeParseAsync(statistics)
      .then((result) => {
        if (!result.success) {
          throw new Error('Invalid statistics response');
        }
        // console.log('Valid statistics response');
        for (const stat of statistics) {
          switch (stat.type) {
            case 'bar':
            case 'pie':
              if (isDataIrrelevant(stat.data.map((x) => x.value))) {
                throw new Error('Irrelevant statistics response');
              }
              break;
            case 'line':
              for (const line of stat.data) {
                if (
                  isDataIrrelevant(line.data.flatMap((dat) => dat.y as number))
                ) {
                  throw new Error('Irrelevant statistics response');
                }
              }
              break;
          }
        }
        this.statistics.set([...existingStatistics, ...statistics]);
        if (!this.user) return;
        trpc.search.setStatistics.mutate({ queryId, statistics });
      })
      .catch((err) => {
        console.error('Invalid statistics response', err);
        this.statistics.set(existingStatistics);
      })
      .finally(() => {
        // console.log('Statistics loaded');
        this.statisticsIsLoaded.set(true);
      });
  }

  /**
   * Retrieve tags
   *
   * @param latest whether to use the latest or state value (false should be used when rendering)
   * @returns
   */
  public getTags(latest = false): string[] {
    return this.tags.get(latest);
  }

  /**
   * Fetch tags from the QnA service, update the DB and then update the saved searches.
   *
   * Cannot be used in rendering (changes state and relies on refs).
   *
   * @param question
   * @returns
   */
  private async fetchTags(question: string): Promise<void> {
    const queryId = this.getQueryId(true);
    const tags = await qnaAPI.fetchTag(question);
    this.tags.set(tags);
    if (!this.user) return;
    await trpc.search.setTags.mutate({ queryId, tags });
    this.savedSearches.fetch();
  }

  /**
   * Fetch suggested follow-ups from the QnA service.
   *
   * Cannot be used in rendering (changes state and relies on refs).
   *
   */

  private async fetchSuggestedFollowUps(): Promise<void> {
    //clear previous suggested follow-ups
    this.suggestedFollowUps.set([]);
    const previous_questions = this.getAllFollowUps(true).map(
      (followUp) => followUp.question,
    );

    const query_id = this.getQueryId(true);
    const lastAddedQuestion = this.getLastAddedQuestion(true);
    const question_id = lastAddedQuestion.getQuestionId(true);
    try {
      this.isFetchingSuggesteFollowUps.set(true);
      const suggestedFollowUps = await qnaAPI.fetchSuggestedQuestions({
        query_id,
        previous_questions,
        question_id,
        num_questions: SUGGESTED_FOLLOW_UPS_NUMBER,
      });
      const uniqueSuggestedFollowUps = [...new Set(suggestedFollowUps)];
      this.setSuggestedFollowUps(uniqueSuggestedFollowUps);
    } catch (error) {
      console.error('Error fetching suggested follow-ups: ', error.message);
    } finally {
      this.isFetchingSuggesteFollowUps.set(false);
    }
  }

  /**
   * Resets all the state
   *
   * Cannot be used in rendering (changes state and relies on refs).
   *
   * @returns
   */
  public clear(): void {
    this.stopStreaming();
    this.setQueryId('');
    this.text.set('');
    this.title.set('');
    this.sources.set([]);
    this.statistics.set([]);
    this.deletedSources.set(new Set());
    this.tags.set([]);
    this.questionAnswers.clear();
    this.suggestedFollowUps.set([]);
    this.summary.stopFetchingAndClear();
    this.chatGPTAnswer.set('');
    this.claudeAnswer.set('');
    this.geminiAnswer.set('');
    this.mistralAnswer.set('');
  }

  /**
   * Create a new search
   *
   * Cannot be used in rendering (changes state and relies on refs).
   *
   * @returns
   */
  public async create(question: string): Promise<void> {
    const authUserId = this.user?.authUserId;
    const userId = this.user?.id;
    if (!authUserId) {
      throw new Error('Auth user id not available');
    }

    const isAllowedToAskQ = await this.isAllowedToAskQuestion(
      userId as number,
      authUserId,
    );
    if (!isAllowedToAskQ) {
      this.setIsPaywallModalOpened(true);

      return;
    }
    this.clear();
    this.summary.disableSummary();
    const newSearch = await trpc.search.create.mutate({ question });
    if (!newSearch) {
      throw new Error('New search could not be created.');
    }
    const { queryId } = newSearch;

    if (!queryId) {
      throw new Error('No query id available.');
    }
    this.setQueryId(queryId);
    this.router.push(`/search/${queryId}`);

    await this.createQuestion(
      question,
      false, // isFollowUp
      true, // isNewSearch
    );
  }

  /**
   * Create a new search
   *
   * Cannot be used in rendering (changes state and relies on refs).
   *
   * @returns
   */
  public async createQuestion(
    question: string,
    isFollowUp: boolean,
    isNewSearch = false,
  ): Promise<void> {
    const authUserId = this.user?.authUserId;
    const userId = this.user?.id;
    if (!authUserId) {
      throw new Error('Auth user id not available');
    }

    if (isFollowUp) {
      const isAllowedToAskQ = await this.isAllowedToAskQuestion(
        userId as number,
        authUserId,
      );
      if (!isAllowedToAskQ) {
        this.setIsPaywallModalOpened(true);
        return;
      }
    }
    const queryId = this.getQueryId(true);
    const newQuestion = await trpc.chat.create.mutate({
      question,
      answer: '',
      queryId,
    });

    let additionalQAProps: AdditionalQAProps = {
      queryId,
      followUpQAs: [],
      deletedSources: [],
      isStreaming: false,
      abort: null,
      retried: false,
      streamEndListeners: [],
    };
    if (isNewSearch) {
      await this.fetchFromDb(queryId);
    }
    if (!isNewSearch) {
      this.fetchSources(newQuestion.question, newQuestion.id);
      this.fetchStatistics(newQuestion.question, newQuestion.id, [], []);
      //TODO: CALCULATE FOLLOWUPS FROM LOCAL QUESTIONANSWERS STATE
      const currentSearch = await trpc.search.getSearch.query(queryId);

      if (!currentSearch) {
        throw new Error(
          'Could not get current search to set followp questions.',
        );
      }
      this.setFollowUps(currentSearch);
      const followUpQAs = this.filterFollowUps(newQuestion.question);
      const deletedSources = this.getDeletedSources(true);

      additionalQAProps = {
        ...additionalQAProps,
        followUpQAs,
        deletedSources,
      };
      this.questionAnswers.append({
        ...newQuestion,
        ...additionalQAProps,
      });

      const lastAddedQuestion = this.getLastAddedQuestion(true);
      lastAddedQuestion.stream();

      const isOverviewActive = this.summary.getIsOverviewActive(true);
      const isKeyTopicsActive = this.summary.getIsKeyTopicsActive(true);

      if (isOverviewActive || isKeyTopicsActive) {
        this.summary.initiateLoadingSkeletons();
        const summaryStreamEndCb = () => {
          const { question } = newQuestion;
          const lastAnswer = lastAddedQuestion.getAnswer(true);
          const allFollowUpQAs = followUpQAs.concat({
            question,
            answer: lastAnswer,
          });
          const summaryData = {
            query_id: queryId,
            question_id: newQuestion.id,
            question,
            source_list: deletedSources,
            followup_qas: allFollowUpQAs,
          };
          this.summary.create(summaryData);
        };
        lastAddedQuestion.addStreamEndListners(summaryStreamEndCb);
      }

      const sources = this.sources.get(true).map((x) => ({
        title: x.title,
        url: x.url,
        isDeleted: deletedSources.includes(x.url),
      }));

      await this.fetchSuggestedFollowUps();
      if (isFollowUp) {
        analytics.generic(
          this.user,
          'Questions asked & answered | Type: User Follow up',
          {
            question,
            queryId,
          },
        );
      }

      analytics.questionAnswered(
        this.user,
        question,
        queryId,
        this.tags.get(true),
        sources,
        newQuestion.answer,
      );
    }
  }

  /**
   * Gets the previous questions asked by the user
   *
   * Cannot be used in rendering (makes an API call).
   *
   * @returns
   */
  async previousQuestions(num = 5): Promise<string[]> {
    let previousQuestions;
    if (this.user?.authUserId) {
      previousQuestions = await trpc.chat.getLastNQuestionsByUser.query({
        numberOfQuestionsToGet: num,
        authUserId: this.user?.authUserId,
      });
    }
    return previousQuestions
      ? previousQuestions.map((el) => el.question).reverse()
      : [];
  }

  private fetchFromQna(
    dbSearch: Awaited<ReturnType<typeof trpc.search.getSearch.query>>,
  ) {
    const question = dbSearch?.QuestionAnswer?.[0].question;
    const questionId = dbSearch?.QuestionAnswer[0].id;
    const queryId = dbSearch?.queryId;
    if (!(question && queryId && questionId)) return;
    if (!dbSearch.name || dbSearch.name === question) {
      this.fetchTitle(question);
    }
    if (dbSearch.sources.length === 0) {
      this.fetchSources(question, questionId);
    }
    if (!dbSearch.statistics?.length) {
      this.fetchStatistics(
        question,
        questionId,
        this.getSources().map((x) => x.url),
        this.getAllFollowUps(true),
      );
    }
    if (dbSearch.tags.length === 0) {
      this.fetchTags(question);
    }
  }

  private uniqueSources(sources: Source[]): Source[] {
    return [...new Map(sources.map((x) => [x.title, x])).values()];
  }

  public async fetchFromDb(queryId: string) {
    const result = await trpc.search.getSearch.query(queryId);
    //TODO: IF NO RESULT, THROW ERROR?
    if (result) {
      result.queryId && this.setQueryId(result.queryId);
      this.setFollowUps(result);
      const deletedSources: Source[] = result?.deletedSources.map((x) => ({
        url: x.url,
        title: x.title,
        isDeleted: true,
      }));
      this.deletedSources.set(new Set(deletedSources));

      this.title.set(result?.name || '');
      this.tags.set(result?.tags.map((x) => x.text) || []);
      this.sources.set(
        result?.sources.map((x) => ({
          title: x.title,
          url: x.url,
          isDeleted: false,
        })) || [],
      );
      this.statistics.set(
        (result?.statistics as unknown as StatisticsResponse[]) || [],
      );
      this.fetchFromQna(result);

      const questionAnswers: QuestionAnswerState[] = result?.QuestionAnswer.map(
        (qa) => {
          const { question } = qa;
          const followUpQAs = this.filterFollowUps(question);

          const additionalQAProps: AdditionalQAProps = {
            queryId,
            followUpQAs,
            deletedSources: deletedSources.map((x) => x.url),
            isStreaming: false,
            abort: null,
            retried: false,
            streamEndListeners: [],
          };

          return {
            ...qa,
            ...additionalQAProps,
          };
        },
      );

      this.questionAnswers.append(...questionAnswers);

      //check if any of the questionAnswers don't have an answer and staret streaming if they don't
      this.getQuestionsAndAnswers(true).forEach((qa) => {
        if (!Boolean(qa.getAnswer(true))) {
          qa.stream();
        }
      });

      //summary section
      //TODO: THIS NEVER HAPPENS WHEN A NEW SEARCH IS CREATED, AS OVERVIEW AND KEY TOPICS
      // ARE BOTH INACTIVE - INVESTIGATE IF RELEVANT
      const isOverviewActive = this.summary.getIsOverviewActive(true);
      const isKeyTopicsActive = this.summary.getIsKeyTopicsActive(true);
      if (isOverviewActive || isKeyTopicsActive) {
        const lastAddedQuestion = result?.QuestionAnswer?.reduce(
          (acc, currentVal) => (currentVal.id > acc.id ? currentVal : acc),
        );

        const { id: question_id, question } = lastAddedQuestion;
        const source_list = this.getDeletedSources(true);
        const followup_qas = this.getAllFollowUps(true); //TODO: CHECK IF THESE ARE ALL THE FOLLOW-UPS
        const summaryReqData = {
          query_id: queryId,
          question_id,
          question,
          source_list,
          followup_qas,
        };

        if (lastAddedQuestion.summary) {
          this.summary.setSummaryValues(
            lastAddedQuestion.summary,
            summaryReqData,
          );
        } else {
          const lastAddedQuestionObj = this.getLastAddedQuestion(true);
          this.summary.initiateLoadingSkeletons();
          const summaryStreamEndCb = () => {
            const allFollowUpQAs = this.questionAnswers.map((qa) => {
              const question = qa.getQuestion(true);
              const answer = qa.getAnswer(true);
              return { question, answer };
            });

            this.summary.create({
              ...summaryReqData,
              followup_qas: allFollowUpQAs,
            });
          };
          lastAddedQuestionObj.addStreamEndListners(summaryStreamEndCb);
        }
      }
    }

    await this.fetchSuggestedFollowUps();
  }

  /**
   * Set or create summary on user triggered action
   *
   */

  public async setOrCreateSummary(): Promise<void> {
    const isQuestionStreaming = this.getIsStreaming(true);
    if (isQuestionStreaming) return;

    const lastAddedQuestion = this.getLastAddedQuestion(true);
    const question_id = lastAddedQuestion.getQuestionId(true);
    const question = lastAddedQuestion.getQuestion(true);
    const query_id = this.getQueryId(true);
    const source_list = this.getDeletedSources(true);
    const followup_qas = this.questionAnswers.map((qa) => {
      const question = qa.getQuestion(true);
      const answer = qa.getAnswer(true);
      return { question, answer };
    });

    const summaryData: RequestData = {
      query_id,
      question,
      question_id,
      source_list,
      followup_qas,
    };

    const lastAddedQuestionSummary =
      await trpc.summary.getByQAId.query(question_id);

    lastAddedQuestionSummary !== null
      ? this.summary.setSummaryValues(lastAddedQuestionSummary, summaryData)
      : this.summary.create(summaryData);
  }

  /**
   * Stop all questions from streaming
   */
  public stopStreaming(): void {
    this.questionAnswers.forEach((qa) => qa.stopStreaming(true));
  }
}

export function useSearch(savedSearches: SavedSearches): Search {
  const { queryId } = useParams<{ queryId: string }>();
  const [user] = useAuth();
  const summary = useSummary();
  return new Search(
    useStateItem(queryId),
    user,
    useRouter(),
    useStateItem(''), // text
    useStateItem(''), // title
    useStateItem([]), // sources
    useStateItem([]), // statistics
    useStateItem(true), // statisticsIsLoaded
    useStateItem(new Set()), // deletedSources
    useStateItem([]), // tags
    savedSearches,
    useStateItems(QuestionAnswer), // questionAnswers
    useStateItem([]), // all follow-up QnAs
    useStateItem([]), //suggested follow-ups
    useStateItem(false), //isFetchingSuggestedFollowUps
    summary, //summary
    useStateItem(''), // chatGPTAnswer
    useStateItem(''), // mistralAnswer
    useStateItem(''), // geminiAnswer
    useStateItem(''), // claudeAnswer
    useStateItem(false), //isPaywallModalOpen
  );
}
